export default {
  more: 'Mais',
  All: 'Tudo',
  CopiedURLToClipboard: 'Link de compartilhamento copiado',
  dleNovel: 'Romance ocioso',
  recommendedVideo: 'Vídeo recomendado',
  urban: 'Urbano',
  campus: 'campus',
  fantasy: 'Fantasia',
  magic: 'Magia',
  martialArts: 'Artes marciais',
  romance: 'Romance',
  fanFiction: 'Fanfic',
  comedy: 'Comédia',
  history: 'História',
  game: 'Jogo',
  military: 'Militar',
  supernatural: 'Sobrenatural',
  mystery: 'mistério',
  allSet: 'O conjunto completo tem {number} episódios',
  episode: 'Episódio {number}',
  Chinese: '中文',
  English: 'English',
  Japanese: '日本語',
  Portuguese: 'Português'
};