import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import axios from 'axios';
import store from '@/store';
import { setHeaders } from './setHeaders';
import { removePending, addPending } from './requestOptimization';
import router from '@/router/index';
import { $t } from '@/lang';
import { ERROR_CODE, WARNING_CODE } from './common';
import Vue from 'vue';
import { getUCode } from '@/utils/auth';
import { EventBus } from '@/utils/eventBus';
var locationUrl = '';
// if (window.location.origin === 'http://localhost:9528') {
//   locationUrl = process.env.VUE_APP_BASE_API;
// } else {
//   locationUrl = window.location.origin + process.env.VUE_APP_BASE_API;
// }

// create an axios instance
var service = axios.create({
  baseURL: locationUrl,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 6 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // 增加自定义配置 （needRemovePending:取消上一次同样的还在pending中的请求,isSilent:不进行统一的报错，自行处理报错）
  var _ref = config.customConfig || {},
    _ref$needRemovePendin = _ref.needRemovePending,
    needRemovePending = _ref$needRemovePendin === void 0 ? true : _ref$needRemovePendin,
    _ref$language = _ref.language,
    language = _ref$language === void 0 ? '' : _ref$language;
  // console.log('请求拦截 config', language);
  if (needRemovePending) {
    removePending(config); // 在请求开始前，对之前的请求做检查取消操作
    addPending(config); // 将当前请求添加到 pending 中
  }
  // do something before request is sent

  if (store.getters.token) {
    setHeaders(config.headers);
  }
  // 单独设置deviceId 没有token的时候也需要传deviceId
  config.headers['X-Device-Id'] = localStorage.getItem('deviceId');
  // 来自于web的请求都加上这个字段
  config.headers['X-Platform'] = 'web';
  if (language) {
    config.headers['Accept-Language'] = language;
  } else {
    var lang = localStorage.getItem('language') || navigator.language || navigator.browserLanguage;
    config.headers['Accept-Language'] = lang;
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var status = response.data.status,
    config = response.config;
  // 增加自定义配置 （needRemovePending:取消上一次同样的还在pending中的请求,isSilent:不进行统一的报错，自行处理报错）
  var _ref2 = config.customConfig || {},
    _ref2$needRemovePendi = _ref2.needRemovePending,
    needRemovePending = _ref2$needRemovePendi === void 0 ? true : _ref2$needRemovePendi,
    _ref2$isSilent = _ref2.isSilent,
    isSilent = _ref2$isSilent === void 0 ? false : _ref2$isSilent;
  var ERROR_MSG = '';
  // 在请求结束后，移除本次请求
  if (needRemovePending) {
    removePending(config);
  }
  // openInviteTask(response);
  redirectToIdle(response);
  var data = response.data;
  if (!data) return;
  // if the custom code is not 20000, it is judged as an error.
  // 41000 检测存在错误
  if (![10000, 10404, 10403, 500401].includes(status.code)) {
    if (isSilent) {
      // 针对注册查询账户接口
      return _objectSpread(_objectSpread({}, data), {}, {
        config: config
      });
    } else if ([20000, 20001, 20002, 20003, 20004, 20301].includes(status.code)) {
      // to re-login
      // store 调用actions logout 退出
      // 封测期间，暂时跳转到登录
      if (status.code === 20301) {
        // 未激活账户跳转到激活页
        router.replace({
          path: '/login',
          query: {
            toActive: true
          }
        });
        return;
      }
      store.dispatch('user/logout').then(function () {
        router.replace('/login');
      });
    } else if (status.code === 150002) {
      // code码为150002 表示搜索敏感词 不提示;
      ERROR_MSG = '';
    } else if (status.code === 70010) {
      // code码为70010 表示该功能需要VIP 弹窗去开VIP;
      ERROR_MSG = '';
      // 使用eventBus 唤起弹窗
      EventBus.$emit('showBusinessTransformation', false);
    } else if (status.code === 80008) {
      // code码为80008 表示算力不足 弹充值弹窗;
      ERROR_MSG = '';
      // 使用eventBus 唤起弹窗
      EventBus.$emit('showBusinessTransformation', true);
    } else {
      ERROR_MSG = ERROR_CODE[status.code] || 'ServerError';
    }
    if (ERROR_MSG) {
      _Message({
        message: $t(ERROR_MSG),
        type: WARNING_CODE.includes(status.code) ? 'warning' : 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject(status);
  } else if ([10403].includes(status.code)) {
    router.replace('/403');
  } else {
    // 快过期会返回token更新
    if (response.headers['token'] || response.headers['Token']) {
      var t = response.headers['token'] || response.headers['Token'];
      store.commit('user/SET_TOKEN', t);
    }
    return _objectSpread(_objectSpread({}, data), {}, {
      config: config
    });
  }
}, function (error) {
  if (redirectToIdle(error === null || error === void 0 ? void 0 : error.response)) {
    return;
  }
  if (error.message == 'requestTooMuchRecord') {
    return Promise.reject(error);
  }
  if (axios.isCancel(error)) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(null);
      }, 31536000000); // 设置时间 为 一年，保持既不成功，也不失败的状态
    });
  }

  console.log('onRejected:', error); // for debug
  _Message({
    message: $t('ServerError'),
    type: 'error',
    duration: 5 * 1000
  });
  // 如果是 激活页 502 错误，则尝试重试请求
  if (error.response && error.response.status === 502 && error.config.url === '/api/v1/account/active') {
    var _error$config;
    var retries = (error === null || error === void 0 ? void 0 : (_error$config = error.config) === null || _error$config === void 0 ? void 0 : _error$config.retries) || 0;
    if (retries < 3) {
      // 最多重试 3 次
      error.config.retries = retries + 1;
      return new Promise(function (resolve) {
        return setTimeout(function () {
          return resolve(service(error.config));
        }, 1000);
      });
    }
  }

  // 否则抛出错误
  console.error(error);
  return Promise.reject(error);
});
var openInviteTask = function openInviteTask(response) {
  var status = response.data.status,
    config = response.config;
  if ((status === null || status === void 0 ? void 0 : status.code) === 10000) {
    if (config.url === '/api/v1/task/create') {
      var _store$state$user, _store$state$user$use, _store$state$payment;
      var data = response.data;
      var inviteTime = ((_store$state$user = store.state.user) === null || _store$state$user === void 0 ? void 0 : (_store$state$user$use = _store$state$user.userInfo) === null || _store$state$user$use === void 0 ? void 0 : _store$state$user$use.invite_user_num) || 0;
      var isVip = ((_store$state$payment = store.state.payment) === null || _store$state$payment === void 0 ? void 0 : _store$state$payment.activeState) || false;
      console.log('响应拦截 response', data.data.is_popup_goto_invite, 'inviteTime', inviteTime, 'isVip', isVip);
      if (data.data && data.data.is_popup_goto_invite && inviteTime < 24 && !isVip) {
        // if (1) {
        Vue.prototype.$EventBus.$emit('openInviteDialog');
      }
    }
  }
};
var redirectToIdle = function redirectToIdle(response) {
  if (!response) return false;
  var _response$status = response.status,
    status = _response$status === void 0 ? 200 : _response$status;
  return false;
};
export default service;