import { videoCategory } from '@/api/home';
export default {
  data: function data() {
    return {
      tagList: [],
      lang: 'Chinese',
      langList: [{
        name: 'Chinese',
        value: 'zh'
      }, {
        name: 'English',
        value: 'en'
      }, {
        name: 'Japanese',
        value: 'ja'
      }, {
        name: 'Portuguese',
        value: 'pt'
      }]
    };
  },
  computed: {
    intLang: function intLang() {
      return this.$i18n.locale;
    },
    isMobile: function isMobile() {
      return localStorage.getItem('visitFrom') === 'mobile';
    }
  },
  created: function created() {
    var _this = this;
    if (!this.isMobile) {
      this.getType();
    }
    this.langList.forEach(function (ele) {
      if (ele.value == localStorage.getItem('language')) {
        _this.lang = ele.name;
      }
    });
  },
  methods: {
    goPage: function goPage(item) {
      window.open("/home?category=" + item, "_blank");
    },
    getType: function getType() {
      var _this2 = this;
      videoCategory().then(function (res) {
        if (res.status.code != 10000) return;
        _this2.tagList = res.data.items;
      });
    },
    setLang: function setLang(item) {
      this.lang = item.name;
      // this.$i18n.locale = item.value;
      localStorage.setItem('language', item.value);
      location.reload();
    }
  }
};