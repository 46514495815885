export default {
  more: '更多',
  All: '全部',
  CopiedURLToClipboard: '分享链接复制成功',
  IdleNovel: '放置小说',
  recommendedVideo: '推荐视频',
  urban: '都市',
  campus: '校园',
  fantasy: '玄幻',
  magic: '奇幻',
  martialArts: '武侠',
  romance: '言情',
  fanFiction: '同人',
  comedy: '搞笑',
  history: '历史',
  game: '游戏',
  military: '军事',
  supernatural: '灵异',
  mystery: '悬疑',
  allSet: '全集 {number} 集',
  episode: '第 {number} 集',
  Chinese: '中文',
  English: 'English',
  Japanese: '日本語',
  Portuguese: 'Português'
};