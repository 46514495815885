var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "videoBox",
    on: {
      mouseenter: function mouseenter($event) {
        return _vm.play(true);
      },
      mouseleave: function mouseleave($event) {
        return _vm.play(false);
      }
    }
  }, [_c("img", {
    attrs: {
      src: _vm.datas.cover,
      alt: ""
    }
  }), _vm._v(" "), _c("video", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shows,
      expression: "shows"
    }],
    ref: "myVideo",
    attrs: {
      src: _vm.datas.uri,
      preload: "none",
      muted: "",
      loop: ""
    },
    domProps: {
      muted: true
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };