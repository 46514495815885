function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import { $t } from '@/lang';
export function getTimeDiff(start, end) {
  var diff = end - start; // 计算时间差（单位为毫秒）
  var minute = 60 * 1000; // 1分钟的毫秒数
  var hour = 60 * minute; // 1小时的毫秒数
  var day = 24 * hour; // 1天的毫秒数
  var month = 30 * day; // 1个月的毫秒数
  var year = 365 * day; // 1年的毫秒数
  if (diff < minute) {
    return $t('Now');
  } else if (diff < hour) {
    return "".concat(Math.floor(diff / minute), " ").concat($t('minutes')).concat($t('ago'));
  } else if (diff < day) {
    return "".concat(Math.floor(diff / hour), " ").concat($t('hours')).concat($t('ago'));
  } else if (diff < month) {
    return "".concat(Math.floor(diff / day), " ").concat($t('days')).concat($t('ago'));
  } else if (diff < year) {
    return "".concat(Math.floor(diff / month), " ").concat($t('months')).concat($t('ago'));
  } else {
    return "".concat(Math.floor(diff / year), " ").concat($t('years')).concat($t('ago'));
  }
}
export function getTimeDiff2(start, end) {
  var diff = end - start; // 计算时间差（单位为毫秒）
  var minute = 60 * 1000; // 1分钟的毫秒数
  var hour = 60 * minute; // 1小时的毫秒数
  var day = 24 * hour; // 1天的毫秒数
  var month = 30 * day; // 1个月的毫秒数
  var year = 365 * day; // 1年的毫秒数
  return parseTime(start);
  // if (diff < minute) {
  // 	return 'now';
  // } else if (diff < hour) {
  // 	return `${Math.floor(diff / minute)} minutes ago`;
  // } else if (diff < day) {
  // 	return `${Math.floor(diff / hour)} hours ago`;
  // } else {
  // 	return parseTime(start);
  // }
}

export function timestampToDate(timestamp) {
  var date = new Date(timestamp);
  var month = getMonthName(date.getMonth()); // 获取月份名称
  var day = date.getDate();
  var year = date.getFullYear();
  return "".concat(month, " ").concat(day, ", ").concat(year);
}
function getMonthName(monthIndex) {
  var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return monthNames[monthIndex];
}

// 时间戳转 日期
export function timestampToTime(_date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy/MM/dd hh:mm:ss';
  if (!_date) {
    return '-';
  }
  var date = new Date(_date);
  return getFormatDate(date, format);
}
function getFormatDate(date, format) {
  var o = {
    'M+': date.getMonth() + 1,
    //月份
    'd+': date.getDate(),
    //日
    'h+': date.getHours(),
    //小时
    'm+': date.getMinutes(),
    //分
    's+': date.getSeconds(),
    //秒
    'q+': Math.floor((date.getMonth() + 3) / 3),
    //季度
    S: date.getMilliseconds() //毫秒
  };

  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp('(' + k + ')').test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  return format;
}
// 输出剩余时间格式， DHMS格式（天-时-分-秒）默认
export function remainingTimeFormat(_timestamp) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DHMS';
  var timestamp = typeof _timestamp === 'string' ? Number(_timestamp) : _timestamp;
  var day = Math.floor(timestamp / 86400);
  var hours = Math.floor(timestamp % 86400 / 3600);
  var minutes = Math.floor(timestamp % 3600 / 60);
  var seconds = timestamp % 60;
  console.log(timestamp, day, hours);
  var formattedDay = day.toString().padStart(2, '0');
  var formattedHours = hours.toString().padStart(2, '0');
  var formattedMinutes = minutes.toString().padStart(2, '0');
  var formattedSeconds = seconds.toString().padStart(2, '0');
  var str = '',
    _format = format.split('');
  for (var i in _format) {
    switch (_format[i]) {
      case 'D':
        str += formattedDay + '天';
        break;
      case 'H':
        str += formattedHours + '小时';
        break;
      case 'M':
        str += formattedMinutes + '分钟';
        break;
      case 'S':
        str += formattedSeconds + '秒';
        break;
    }
  }
  return str;
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}
export function timeRecently(timestamp) {
  var newTime = new Date().getTime();
  return getTimeDiff(timestamp, newTime);
}