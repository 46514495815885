export default {
  props: {
    datas: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      shows: false,
      startTime: null
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.$refs.myVideo.addEventListener("contextmenu", function (event) {
      event.preventDefault();
    });
  },
  methods: {
    play: function play(isPlay) {
      var _this = this;
      if (isPlay) {
        this.shows = true;
        this.startTime = setTimeout(function () {
          _this.$refs.myVideo.src = _this.datas.uri;
          _this.$refs.myVideo.play();
        }, 1000);
      } else {
        clearTimeout(this.startTime);
        this.shows = false;
        if (!this.$refs.myVideo.paused) {
          this.$refs.myVideo.pause();
          this.$refs.myVideo.currentTime = 0;
          this.$refs.myVideo.src = null;
        }
      }
    }
  }
};