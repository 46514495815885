var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("el-container", {
    staticClass: "container-wraper"
  }, [!_vm.$route.meta.hiddenTopMenu ? _c("headBox") : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "container",
    style: {
      padding: _vm.$route.meta.hiddenTopMenu || _vm.$route.meta.noPadding ? 0 : "60px 0 0 0"
    }
  }, [!_vm.$route.meta.types ? _c("keep-alive", {
    attrs: {
      include: _vm.cachedViews
    }
  }, [_c("router-view")], 1) : _c("router-view", {
    key: _vm.$route.path
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };