var render = function render() {
  var _vm$datas, _vm$datas$segments$_v, _vm$datas2, _vm$datas3, _vm$datas4, _vm$datas5, _vm$datas6, _vm$datas7, _vm$datas8, _vm$datas9, _vm$datas10;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "videoInfo",
    class: {
      mobileVideo: _vm.isMobile
    }
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "player"
  }, [_c("video", {
    ref: "myVideo",
    attrs: {
      src: (_vm$datas = _vm.datas) === null || _vm$datas === void 0 ? void 0 : (_vm$datas$segments$_v = _vm$datas.segments[_vm.playerNum]) === null || _vm$datas$segments$_v === void 0 ? void 0 : _vm$datas$segments$_v.uri,
      controlslist: "nodownload",
      controls: "",
      autoplay: ""
    },
    on: {
      ended: _vm.endPlay,
      timeupdate: _vm.onTimeupdate
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "nanmNum"
  }, [_vm._v("\n      " + _vm._s("".concat(_vm.$t("episode", {
    number: _vm.playerNum + 1
  }), " \xA0 \xA0 ").concat((_vm$datas2 = _vm.datas) === null || _vm$datas2 === void 0 ? void 0 : _vm$datas2.name)) + "\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "playInfo"
  }, [_c("div", {
    staticClass: "playNum"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "icon_play"
    }
  }), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.formatNumberForeign((_vm$datas3 = _vm.datas) === null || _vm$datas3 === void 0 ? void 0 : _vm$datas3.view_count)))])], 1), _vm._v(" "), _c("div", {
    staticClass: "playNum",
    staticStyle: {
      "margin-left": "36px",
      cursor: "pointer"
    },
    on: {
      click: _vm.share
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "share"
    }
  }), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.formatNumberForeign((_vm$datas4 = _vm.datas) === null || _vm$datas4 === void 0 ? void 0 : _vm$datas4.share_count)))])], 1), _vm._v(" "), _c("div", {
    staticClass: "playNum",
    staticStyle: {
      "margin-left": "36px"
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "time"
    }
  }), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.getTimeDiff2((_vm$datas5 = _vm.datas) === null || _vm$datas5 === void 0 ? void 0 : _vm$datas5.update_at)))])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "videoTags"
  }, _vm._l((_vm$datas6 = _vm.datas) === null || _vm$datas6 === void 0 ? void 0 : _vm$datas6.tag, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tagItem"
    }, [_vm._v(_vm._s(item))]);
  }), 0)]), _vm._v(" "), _c("div", {
    staticClass: "right"
  }, [(_vm$datas7 = _vm.datas) !== null && _vm$datas7 !== void 0 && _vm$datas7.segments ? _c("div", {
    staticClass: "videoTop"
  }, [_vm._v("\n      " + _vm._s("".concat(_vm.$t("allSet", {
    number: (_vm$datas8 = _vm.datas) === null || _vm$datas8 === void 0 ? void 0 : _vm$datas8.segments.length
  })).concat((_vm$datas9 = _vm.datas) !== null && _vm$datas9 !== void 0 && _vm$datas9.category ? " · " + _vm.setCategory() : "")) + "\n    ")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "videoList"
  }, _vm._l((_vm$datas10 = _vm.datas) === null || _vm$datas10 === void 0 ? void 0 : _vm$datas10.segments, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "videoItem",
      class: {
        videoActivate: index == _vm.playerNum
      },
      on: {
        click: function click($event) {
          return _vm.changeVideo(index);
        }
      }
    }, [_vm._v(_vm._s(index + 1))]);
  }), 0)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };