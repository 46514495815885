import fetch from '@/utils/fetch';

/**
Home列表查询
*/
export function videoList(data) {
  return fetch({
    url: '/api/v1/video/list',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}
//视频详情
export function videoDetail(data) {
  return fetch({
    url: '/api/v1/video/detail',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}
//视频分类
export function videoCategory(data) {
  return fetch({
    url: '/api/v1/video/category',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}
//数据上报
export function report(data) {
  return fetch({
    url: '/api/v1/dot/report',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}