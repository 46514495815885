export default {
  more: 'more',
  All: 'All',
  CopiedURLToClipboard: 'Sharing link copied',
  IdleNovel: 'Idle Novel',
  recommendedVideo: 'Recommended video',
  urban: 'urban',
  campus: 'campus',
  fantasy: 'fantasy',
  magic: 'magic',
  martialArts: 'martialArts',
  romance: 'romance',
  fanFiction: 'fanFiction',
  comedy: 'comedy',
  history: 'history',
  game: 'game',
  military: 'military',
  supernatural: 'supernatural',
  mystery: 'mystery',
  allSet: 'The complete set has {number} episodes',
  Chinese: '中文',
  English: 'English',
  Japanese: '日本語',
  Portuguese: 'Português',
  episode: 'Episode {number}'
};