var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "home",
    class: {
      mobileHome: _vm.isMobile
    }
  }, [_c("el-backtop", [_c("i", {
    staticClass: "el-icon-top"
  })]), _vm._v(" "), _c("SwipeableList", {
    attrs: {
      scrollChange: _vm.imgScroll,
      iconBntShow: false,
      top: "0"
    }
  }, [_c("div", {
    staticClass: "tags"
  }, _vm._l(_vm.tagList, function (item, index) {
    return _c("div", {
      key: index,
      ref: "tagItem",
      refInFor: true,
      staticClass: "tagItem",
      class: {
        tagActivate: _vm.queryParam.category == item
      },
      on: {
        click: function click($event) {
          return _vm.changeTag(item);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item)))]);
  }), 0)]), _vm._v(" "), !_vm.queryParam.category ? _c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("recommendedVideo")))]) : _vm._e(), _vm._v(" "), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isReq,
      expression: "isReq"
    }],
    staticClass: "list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: item.id + index,
      staticClass: "listItem",
      on: {
        click: function click($event) {
          return _vm.goPage(item);
        }
      }
    }, [_c("div", {
      staticClass: "listItemCnt"
    }, [_c("videoBox", {
      attrs: {
        datas: item
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "mask"
    }, [_c("div", {
      staticClass: "playNum"
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": "icon_play"
      }
    }), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.formatNumberForeign(item.view_count)))])], 1), _vm._v(" "), _c("div", {
      staticClass: "palyTime"
    }, [_vm._v(_vm._s(_vm.timeFormat(item.length)))])])], 1), _vm._v(" "), _c("div", {
      staticClass: "listInfo"
    }, [_c("div", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _vm._v(" "), _c("div", {
      staticClass: "desc"
    }, [_vm._v("\n            " + _vm._s(_vm.$t("IdleNovel")) + "· " + _vm._s(_vm.formatDate(item.update_at)) + "\n          ")])])]);
  }), 0)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };