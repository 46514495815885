import { videoDetail, report } from '@/api/home';
import { getTimeDiff2 } from '@/utils/diffDate';
import { copyTextToClipboard, formatNumberForeign } from '@/utils/tool';
export default {
  name: 'VideoDetail',
  data: function data() {
    return {
      formatNumberForeign: formatNumberForeign,
      getTimeDiff2: getTimeDiff2,
      datas: null,
      playerNum: 0,
      playerHistory: []
    };
  },
  computed: {
    isMobile: function isMobile() {
      return localStorage.getItem('visitFrom') === 'mobile';
    }
  },
  created: function created() {
    var _this = this;
    this.getData();
    this.setReport(1);
    if (localStorage.getItem('playHistory')) {
      this.playerHistory = JSON.parse(localStorage.getItem('playHistory'));
      this.playerHistory.forEach(function (ele) {
        if (ele.id == _this.$route.params.id) {
          _this.playerNum = ele.playNum;
        }
      });
    }
  },
  mounted: function mounted() {
    this.$refs.myVideo.addEventListener("contextmenu", function (event) {
      event.preventDefault();
    });
  },
  methods: {
    onTimeupdate: function onTimeupdate() {
      var _this2 = this;
      var intHistory = {
        id: this.$route.params.id,
        playNum: this.playerNum,
        playTime: this.$refs.myVideo.currentTime
      };
      if (this.playerHistory.length > 0) {
        var isAdd = true;
        this.playerHistory.forEach(function (ele, idx) {
          if (ele.id == intHistory.id) {
            isAdd = false;
            _this2.playerHistory[idx] = intHistory;
          }
        });
        if (isAdd) {
          this.playerHistory.push(intHistory);
        }
      } else {
        this.playerHistory.push(intHistory);
      }
      localStorage.setItem('playHistory', JSON.stringify(this.playerHistory));
    },
    setReport: function setReport(type) {
      report({
        parts: [{
          action: type,
          segment: this.playerNum + 1,
          video_id: this.$route.params.id
        }]
      });
    },
    share: function share() {
      copyTextToClipboard(window.location.href);
      this.setReport(2);
      this.$message({
        message: this.$t('CopiedURLToClipboard'),
        type: 'success'
      });
    },
    setCategory: function setCategory() {
      var _this3 = this;
      this.datas.category.forEach(function (ele, idx) {
        _this3.datas.category[idx] = _this3.$t(ele);
      });
      return this.datas.category.join(' · ');
    },
    changeVideo: function changeVideo(index) {
      this.playerNum = index;
      this.setReport(1);
    },
    endPlay: function endPlay() {
      if (this.playerNum < this.datas.segments.length - 1) {
        this.playerNum++;
      }
    },
    getData: function getData() {
      var _this4 = this;
      videoDetail({
        video_id: this.$route.params.id
      }).then(function (res) {
        if (res.status.code != 10000) return;
        _this4.datas = res.data;
        _this4.$nextTick(function () {
          _this4.playerHistory.forEach(function (ele) {
            if (ele.id == _this4.$route.params.id) {
              _this4.$refs.myVideo.currentTime = ele.playTime;
            }
          });
        });
      });
    }
  }
};