export default {
  props: {
    iconBntWidth: {
      //箭头按钮大小
      type: Number,
      default: 40
    },
    iconBntShow: {
      //箭头按钮显示
      type: Boolean,
      default: true
    },
    scrollLeftnum: {
      //列表内部高亮元素位置
      type: Number,
      default: null
    },
    scrollChange: {
      // 点击元素的位置
      type: Object,
      default: null
    },
    moveLeng: {
      //点击箭头位移距离
      type: Number,
      default: 600
    },
    top: {
      //上边距
      type: String,
      default: '16px'
    }
  },
  data: function data() {
    return {
      isShowLeft: false,
      isShowRight: true,
      savedScrollLeft: 0 //保存偏移量，当进入缓存页面时候偏移量会重置为0导致左箭头失效
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$refs.scrollArea.addEventListener('scroll', this.setMoveBtnShow);
    this.$once('hook:beforeDestroy', function () {
      _this.$refs.scrollArea.removeEventListener('scroll', _this.setMoveBtnShow);
    });
  },
  updated: function updated() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.setMoveBtnShow();
    });
  },
  watch: {
    scrollLeftnum: {
      //监听列表中高亮元素的位置变化 使之进入页面后滚动到页面中间显示
      handler: function handler(val) {
        if (val && val > 0) {
          this.$refs.scrollArea.scrollLeft = val - this.$refs.scrollArea.getBoundingClientRect().left - this.$refs.scrollArea.offsetWidth / 2 + 50;
        }
      }
    },
    scrollChange: {
      // 监听点击元素的位置变化 使之滚动到页面中间显示
      handler: function handler(val) {
        // console.log('watch 点击 变化', val, val.value)
        if (val.value) {
          var offset = val.value - this.$refs.scrollArea.getBoundingClientRect().left - this.$refs.scrollArea.offsetWidth / 2 + 50;
          // console.log('点击位置', '左侧距离', '1/2宽度', '加上100');
          // console.log('计算过程', val.value, '-', this.$refs.scrollArea.getBoundingClientRect().left, '-', this.$refs.scrollArea.offsetWidth / 2, '+ ', 100);
          // console.log('计算结果', offset);
          this.tagsMove(offset);
        }
      }
    }
  },
  activated: function activated() {
    var _this3 = this;
    this.$nextTick(function () {
      _this3.setMoveBtnShow();
    });
    //当页面被激活,把保存的偏移量赋值给列表偏移
    this.$refs.scrollArea.scrollLeft = this.savedScrollLeft;
  },
  methods: {
    setMoveBtnShow: function setMoveBtnShow() {
      // 设置左右切换按钮显隐
      var list = this.$refs.scrollArea;
      if (list <= this.$refs.tags.offsetWidth) {
        // 检查列表是否超出容器宽度
        this.isShowRight = false;
      }
      if (list.scrollLeft === 0) {
        this.isShowLeft = false;
      } else {
        this.isShowLeft = true;
      }
      if (list.scrollLeft + list.clientWidth === list.scrollWidth) {
        this.isShowRight = false;
      } else {
        this.isShowRight = true;
      }
      // 将当前的 scrollLeft 值保存到组件的 data 中
      this.savedScrollLeft = this.$refs.scrollArea.scrollLeft;
    },
    tagsMove: function tagsMove(moveLeng) {
      //按钮点击滚动
      this.$refs.scrollArea.scrollBy({
        left: moveLeng,
        behavior: 'smooth'
      });
    }
  }
};