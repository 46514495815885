var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "headBox"
  }, [_c("div", {
    staticClass: "headLeft"
  }, [_c("a", {
    staticClass: "logText",
    attrs: {
      href: "/home"
    }
  }, [_c("img", {
    attrs: {
      src: this.intLang == "zh-CN" ? require("@/assets/logo-cn.webp") : require("@/assets/logo-en.webp"),
      alt: ""
    }
  })]), _vm._v(" "), !_vm.isMobile ? _c("div", {
    staticClass: "tags"
  }, [_vm._l(_vm.tagList.filter(function (ele, idx) {
    return idx < 6;
  }), function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tagItem",
      class: {
        tagActivate: _vm.$route.query.category == item
      },
      on: {
        click: function click($event) {
          return _vm.goPage(item);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item)))]);
  }), _vm._v(" "), _vm.tagList.length > 0 ? _c("el-popover", {
    attrs: {
      placement: "bottom",
      "visible-arrow": false,
      trigger: "hover"
    }
  }, [_c("div", {
    staticClass: "tagPopCnt"
  }, _vm._l(_vm.tagList.filter(function (ele, idx) {
    return idx > 5;
  }), function (item, index) {
    return _c("span", {
      key: index,
      staticClass: "tagPopCntItem",
      class: {
        tagActivate: _vm.$route.query.category == item
      },
      on: {
        click: function click($event) {
          return _vm.goPage(item);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item)))]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "tagPopBnt",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("more")))]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])]) : _vm._e()], 2) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "headRight"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "langIcon"
    }
  }), _vm._v(" "), _c("el-dropdown", {
    attrs: {
      trigger: "hover"
    },
    on: {
      command: _vm.setLang
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_vm._v("\n        " + _vm._s(_vm.$t(_vm.lang))), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.langList, function (item, index) {
    return _c("el-dropdown-item", {
      key: index,
      attrs: {
        command: item
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]);
  }), 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };