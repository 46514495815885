import store from '@/store';
import { getToken } from '@/utils/auth'; // get token
export var setHeaders = function setHeaders() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (store.getters.token) {
    config['Token'] = getToken();
    // config['X-Device-Id'] = localStorage.getItem('deviceId');
  }

  return config;
};