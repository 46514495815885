export var PREPROCESSOR = ['canny', 'depth', 'depth_leres', 'hed', 'mlsd', 'normal_map', 'openpose', 'pidinet', 'scribble', 'fake_scribble', 'segmentation'];

//套餐类型
export var COMBO_TYPE = [{
  label: '1日卡',
  value: 'day1'
}, {
  label: '7日卡',
  value: 'day7'
}, {
  label: '30日卡',
  value: 'day30'
}, {
  label: '90日卡',
  value: 'day90'
}, {
  label: '180日卡',
  value: 'day180'
}, {
  label: '360日卡',
  value: 'day360'
}];
export var COIN_TYPE = [{
  label: '算力',
  value: 4
}, {
  label: '体力(临时算力)',
  value: 1
}];
export var ORDER_STATUS = [{
  label: '待支付',
  value: 0
}, {
  label: '已支付',
  value: 1
}, {
  label: '退款',
  value: 2
}];
export var ERROR_CODE = {
  // 请求错误码解析
  110000: 'CreateChannelMAX',
  110001: 'JoinChannelRepetition',
  110002: 'InviteExpires',
  110003: 'JoinChannelMAX',
  110004: 'ChannelExist',
  110005: 'ChannelNotExist',
  110006: 'ChannelPersonFullUp',
  110007: 'ChannelNameIllegal',
  110008: 'ChannelDescillegal',
  110009: 'channelHasDisband',
  20306: 'ImgLimitReached',
  80044: 'InsufficientEntitlements',
  30005: 'uploadImageViolatesRules',
  70000: 'taskOperateFailed',
  70001: 'taskExceededDailyGenerationLimit',
  70002: 'taskQueueFull',
  70003: 'taskModelVersionNotSupportVariations',
  70009: 'taskModelForbidden',
  70008: 'taskArtWorkForbidden',
  70025: 'HttpErrorCode_70025',
  // 80008: 'InsufficientBalance',
  30000: 'uploadError',
  30001: 'uploadFileTimeOut',
  30002: 'HttpErrorCode_30002',
  30003: 'HttpErrorCode_30003',
  30004: 'HttpErrorCode_30004',
  30006: 'HttpErrorCode_30006',
  30007: 'HttpErrorCode_30007',
  30008: 'HttpErrorCode_30008',
  30009: 'HttpErrorCode_30009',
  30011: 'HttpErrorCode_30011',
  30012: 'HttpErrorCode_30012',
  30015: 'HttpErrorCode_30015',
  30016: 'HttpErrorCode_30016',
  30020: 'HttpErrorCode_30020',
  30021: 'HttpErrorCode_30021',
  30022: 'HttpErrorCode_30022',
  30023: 'HttpErrorCode_30023',
  30024: 'HttpErrorCode_30024',
  30025: 'HttpErrorCode_30025',
  30026: 'HttpErrorCode_30026',
  20310: 'HttpErrorCode_20310',
  120000: 'trainLimit',
  120001: 'trainAlready',
  120002: 'least9',
  120003: 'trainModelVersion',
  130000: 'HttpErrorCode_130000',
  130001: 'HttpErrorCode_130001',
  130002: 'HttpErrorCode_130002',
  130003: 'HttpErrorCode_130003',
  130004: 'HttpErrorCode_130004',
  10203: 'alreadyDone',
  120004: 'trainImgLimit',
  140000: 'VideoNameIllegal',
  140001: 'VideoDesIllegal',
  140002: 'VideoNameRepeat',
  140003: 'VideoMakeNumMax',
  70018: 'HttpErrorCode_70018',
  30032: 'HttpErrorCode_30032',
  30033: 'HttpErrorCode_30033',
  30029: '导出xlsx数据失败',
  30030: '列表没数据',
  120005: 'InsufficientTraining',
  80202: 'HttpErrorCode_80202',
  70021: 'UnlimitedQueueNotSupport',
  40020: 'collectDeleteLimit',
  70011: 'error_code_70011',
  70013: 'error_code_70013',
  70014: 'error_code_70014',
  70015: 'error_code_70015',
  70016: 'error_code_70016',
  70019: 'error_code_70019',
  70024: 'error_code_70024',
  10405: 'code_10405',
  10407: 'duplicateAppeal',
  10408: 'code_10408',
  20302: 'UserNameRepeat',
  20305: 'NicknameLimitReached',
  20001: 'AccountNoExistent',
  //'账户不存在',
  20004: 'EmailDisabled',
  //'账户被禁止',
  20005: 'PasswordError',
  //'密码错误',
  20002: 'ResetLinkExpired',
  20003: 'ResetLinkExpired',
  20100: 'InvalidEmail',
  //'email无效'
  20101: 'EmailRegistered',
  20105: 'VerifiCodeError',
  //'验证码错误'
  70022: 'error_code_70022',
  40008: 'error_code_40008',
  40103: 'error_code_40103',
  10410: 'error_code_10410',
  10406: '模型任务过多',
  10601: 'error_code_10601',
  10602: 'error_code_10602',
  10603: 'error_code_10603',
  10604: 'error_code_10604'
};
export var WARNING_CODE = [];
export var REPORT_TYPE = ['色情', '政治', '军事', '暴力', '恐怖', '毒品', '侮辱', '劣迹名人', '其他'];
export var MODEL_COVER = {
  canny: '/static/upload/20230516/97521ecb-927c-459b-a0ef-8a017046898e.png',
  openpose_full: '/static/upload/20230516/5a101a9c-3fdb-43ce-be17-17ad2ac34064.png',
  lineart: '/static/upload/20230516/20528205-31e4-4332-9f46-a7564496eabe.png',
  lineart_anime: '/static/upload/20230516/dfdec1d5-4022-411b-99fa-99fb70879420.png',
  depth: '/static/upload/20230516/251f91fa-fae1-429b-ba69-d0727592797a.png',
  normal_bae: '/static/upload/20230516/f7842785-71b1-4d4b-90bf-fbc4016c9f7e.png',
  segmentation: '/static/upload/20230516/30f009d3-03f8-4fa3-bc92-9f8773fc5242.png',
  tile_resample: '/static/upload/20230522/8f9318c7-d8e8-4206-a452-b3371e59cf49.png',
  mlsd: '/static/upload/20230516/9b471d03-7dc4-410e-86b6-093253e9a982.png',
  scribble_hed: '/static/upload/20230516/c5c45a9e-d805-4421-82c7-fbd0012c08db.png',
  hed_safe: '/static/upload/20230516/85f89b7a-476d-4ef6-97d7-d83966a4ac27.png',
  t2ia_color_grid: '/static/upload/20230516/5b8554a3-581b-480c-8752-b2ee51ccb028.png',
  shuffle: '/static/upload/20230516/50f6c1c2-fe1a-4778-86e9-efbab45be33f.png',
  reference_only: '/static/upload/20230520/4e3464a6-d850-429d-a404-62ff8209bd28.png'
};
export var TASK_TYPE = {
  1: '图生图',
  2: '超分',
  3: '变体',
  4: '超分',
  5: '移除背景',
  6: '图像描述',
  7: '图生图',
  8: '条件生图',
  10: '预览图'
};
export var SD_LIST = ['SD 1.4', 'SD 1.5', 'SD 2.0', 'SD 2.0 768', 'SD 2.1', 'SD 2.1 768', 'Other'];
export var METHOD_STEP = {
  'Euler a': 20,
  Euler: 20,
  LMS: 20,
  Heun: 20,
  DPM2: 20,
  'DPM2 a': 20,
  'DPM++ 2S a': 20,
  'DPM++ 2M': 20,
  'DPM++ SDE': 15,
  'DPM fast': 20,
  'LMS Karras': 20,
  'DPM2 Karras': 20,
  'DPM2 a Karras': 20,
  'DPM++ 2S a Karras': 15,
  'DPM++ 2M Karras': 20,
  'DPM++ SDE Karras': 15,
  DDIM: 20
};
export var CREATIVITY_MODEL = [{
  label: 6,
  value: 3
}, {
  label: 8,
  value: 2
}, {
  label: 9,
  value: 1
}, {
  label: 10,
  value: 4
}];
export var IMAGE_MODE = [{
  code: 1,
  label: 'imageMode1',
  width: 512,
  height: 512
}, {
  code: 2,
  label: 'imageMode2',
  width: 768,
  height: 768
}, {
  code: 3,
  label: 'imageMode3',
  width: 1024,
  height: 1024
}];
export var FAST_SIZE = [{
  code: 1,
  label: '1 : 1',
  width: 1024,
  height: 1024
}, {
  code: 2,
  label: '3 : 4',
  width: 771,
  height: 1024
}, {
  code: 3,
  label: '2 : 3',
  width: 683,
  height: 1024
}, {
  code: 4,
  label: '9 : 16',
  width: 575,
  height: 1024
}, {
  code: 5,
  label: '4 : 3',
  width: 1024,
  height: 771
}, {
  code: 6,
  label: '3 : 2',
  width: 1024,
  height: 683
}, {
  code: 7,
  label: '16 : 9',
  width: 1024,
  height: 575
}, {
  code: 8,
  label: 'Customization',
  width: 0,
  height: 0
}];

// 海艺实验室使用的宽高比
export var FAST_SIZE2 = [{
  code: 9,
  label: '1 : 1',
  width: 1024,
  height: 1024
}, {
  code: 10,
  label: '9 : 7',
  width: 1152,
  height: 896
}, {
  code: 11,
  label: '7 : 9',
  width: 896,
  height: 1152
}, {
  code: 12,
  label: '19 : 13',
  width: 1216,
  height: 832
}, {
  code: 13,
  label: '13 : 19',
  width: 832,
  height: 1216
}, {
  code: 14,
  label: '16 : 9',
  width: 1344,
  height: 768
}, {
  code: 15,
  label: '9 : 16',
  width: 768,
  height: 1344
}
// {
// 	code: 16,
// 	label: '12 : 5',
// 	width: 1536,
// 	height: 640
// },
// {
// 	code: 17,
// 	label: '5 : 12',
// 	width: 640,
// 	height: 1536
// }
];

export var PORTFOLIO_STATUS = ['待发布', '已发布', '已删除', '已下架'];
export var LOG_ACTION = ['全部', '增', '删', '改'];
export var LOG_OBJECT_TYPE = {
  model: '模型',
  art_work: '作品',
  account: '账号',
  manager: '管理员',
  assets: '权益',
  report: '举报',
  order: '订单',
  announcement: '公告',
  task: '任务',
  comment: '评论',
  script: '脚本',
  resource_appeal: '申诉',
  black_list: '黑名单',
  banner: '广告',
  tool: '工具',
  portfolio: '作品集',
  sensitive_words: '敏感词',
  invite_code: '邀请码',
  image_risk: '图片黑白名单规则',
  video: '视频'
};
export var MODEL_SOURCE = {
  All: '全部',
  user_upload: '用户上传',
  import: '官方导入',
  train: '用户训练'
};
export var PAY_TYPE = ['-', '算力', '钻石'];
export var MODEL_STATUS = ['全部', '正常', '禁用', '待审核', '审核不通过'];
export var INVITATION_CODE_STATUS = ['全部', '生效', '失效', '待生效'];
export var IMG_RULE_REGION_KEY = {
  common: '公共',
  china: '国内',
  foreign: '国外'
};
export var PAY_SOURCE = ['payer_max', 'google', 'apple', 'alipay', 'wechat', 'paypal'];
export var COUNTRY_CODE = {
  AF: '阿富汗',
  AL: '阿尔巴尼亚',
  DZ: '阿尔及利亚',
  AS: '美属萨摩亚',
  AD: '安道尔',
  AO: '安哥拉',
  AI: '安圭拉',
  AQ: '南极洲',
  AG: '安提瓜和巴布达',
  AR: '阿根廷',
  AM: '亚美尼亚',
  AW: '阿鲁巴',
  AU: '澳大利亚',
  AT: '奥地利',
  AZ: '阿塞拜疆',
  BS: '巴哈马',
  BH: '巴林',
  BD: '孟加拉国',
  BB: '巴巴多斯',
  BY: '白俄罗斯',
  BE: '比利时',
  BZ: '伯利兹',
  BJ: '贝宁',
  BM: '百慕大',
  BT: '不丹',
  BO: '玻利维亚（多民族国）',
  BA: '波黑共和国',
  BW: '博茨瓦纳',
  BV: '布韦岛',
  BR: '巴西',
  IO: '英属印度洋领地',
  BN: '文莱达鲁萨兰国',
  BG: '保加利亚',
  BF: '布基纳法索',
  BI: '布隆迪',
  KH: '柬埔寨',
  CM: '喀麦隆',
  CA: '加拿大',
  CV: '佛得角',
  KY: '开曼群岛',
  CF: '中非共和国',
  TD: '乍得',
  CL: '智利',
  CN: '中国',
  CX: '圣诞岛',
  CC: '科科斯（基林）群岛',
  CO: '哥伦比亚',
  KM: '科摩罗',
  CD: '刚果民主共和国',
  CG: '刚果共和国',
  CK: '库克群岛',
  CR: '哥斯达黎加',
  CI: '科特迪瓦',
  HR: '克罗地亚',
  CU: '古巴',
  CY: '塞浦路斯',
  CZ: '捷克共和国',
  DK: '丹麦',
  DJ: '吉布提',
  DM: '多米尼克',
  DO: '多米尼加共和国',
  EC: '厄瓜多尔',
  EG: '埃及',
  SV: '萨尔瓦多',
  GQ: '赤道几内亚',
  ER: '厄立特里亚',
  EE: '爱沙尼亚',
  ET: '埃塞俄比亚',
  FK: '福克兰群岛（马尔维纳斯）',
  FO: '法罗群岛',
  FJ: '斐济',
  FI: '芬兰',
  FR: '法国',
  GF: '法属圭亚那',
  PF: '法属波利尼西亚',
  TF: '法属南部领土',
  GA: '加蓬',
  GM: '冈比亚',
  GE: '格鲁吉亚',
  DE: '德国',
  GH: '加纳',
  GI: '直布罗陀',
  GR: '希腊',
  GL: '格陵兰',
  GD: '格林纳达',
  GP: '瓜德罗普',
  GU: '关岛',
  GT: '危地马拉',
  GG: '根西岛',
  GN: '几内亚',
  GW: '几内亚比绍',
  GY: '圭亚那',
  HT: '海地',
  HM: '赫德和麦当劳群岛',
  VA: '梵蒂冈城国',
  HN: '洪都拉斯',
  HK: '香港',
  HU: '匈牙利',
  IS: '冰岛',
  IN: '印度',
  ID: '印度尼西亚',
  IR: '伊朗（伊斯兰共和国）',
  IQ: '伊拉克',
  IE: '爱尔兰',
  IM: '马恩岛',
  IL: '以色列',
  IT: '意大利',
  JM: '牙买加',
  JP: '日本',
  JE: '泽西岛',
  JO: '约旦',
  KZ: '哈萨克斯坦',
  KE: '肯尼亚',
  KI: '基里巴斯',
  KP: '朝鲜民主主义人民共和国',
  KR: '韩国共和国',
  KW: '科威特',
  KG: '吉尔吉斯斯坦',
  LA: '老挝人民民主共和国',
  LV: '拉脱维亚',
  LB: '黎巴嫩',
  LS: '莱索托',
  LR: '利比里亚',
  LY: '阿拉伯利比亚民众国',
  LI: '列支敦士登',
  LT: '立陶宛',
  LU: '卢森堡',
  MO: '澳门',
  MK: '马其顿，前南斯拉夫共和国',
  MG: '马达加斯加',
  MW: '马拉维',
  MY: '马来西亚',
  MV: '马尔代夫',
  ML: '马里',
  MT: '马耳他',
  MH: '马绍尔群岛',
  MQ: '马提尼克',
  MR: '毛里塔尼亚',
  MU: '毛里求斯',
  YT: '马约特',
  MX: '墨西哥',
  FM: '密克罗尼西亚联邦',
  MD: '摩尔多瓦，共和国',
  MC: '摩纳哥',
  MN: '蒙古',
  ME: '黑山',
  MS: '蒙特塞拉特',
  MA: '摩洛哥',
  MZ: '莫桑比克',
  MM: '缅甸',
  NA: '纳米比亚',
  NR: '瑙鲁',
  NP: '尼泊尔',
  NL: '荷兰',
  AN: '荷属安的列斯群岛',
  NC: '新喀里多尼亚',
  NZ: '新西兰',
  NI: '尼加拉瓜',
  NE: '尼日尔',
  NG: '尼日利亚',
  NU: '纽埃',
  NF: '诺福克岛',
  MP: '北马里亚纳群岛',
  NO: '挪威',
  OM: '阿曼',
  PK: '巴基斯坦',
  PW: '帕劳',
  PS: '巴勒斯坦领土',
  PA: '巴拿马',
  PG: '巴布亚新几内亚',
  PY: '巴拉圭',
  PE: '秘鲁',
  PH: '菲律宾',
  PN: '皮特凯恩群岛',
  PL: '波兰',
  PT: '葡萄牙',
  PR: '波多黎各',
  QA: '卡塔尔',
  RE: '留尼汪',
  RO: '罗马尼亚',
  RU: '俄罗斯联邦',
  RW: '卢旺达',
  BL: '圣巴泰勒米',
  SH: '圣赫勒拿、阿森松和特里斯坦达库尼亚群岛',
  KN: '圣基茨和尼维斯',
  LC: '圣卢西亚',
  MF: '圣马丁',
  PM: '圣皮埃尔和密克隆群岛',
  VC: '圣文森特和格林纳丁斯',
  WS: '萨摩亚',
  SM: '圣马力诺',
  ST: '圣多美和普林西比',
  SA: '沙特阿拉伯',
  SN: '塞内加尔',
  RS: '塞尔维亚',
  SC: '塞舌尔',
  SL: '塞拉利昂',
  SG: '新加坡',
  SK: '斯洛伐克',
  SI: '斯洛文尼亚',
  SB: '所罗门群岛',
  SO: '索马里',
  ZA: '南非',
  GS: '南乔治亚岛和南桑威奇群岛',
  SS: '南苏丹',
  ES: '西班牙',
  LK: '斯里兰卡',
  SD: '苏丹',
  SR: '苏里南',
  SJ: '斯瓦尔巴群岛和扬马延岛',
  SZ: '斯威士兰',
  SE: '瑞典',
  CH: '瑞士',
  SY: '阿拉伯叙利亚共和国',
  TW: '台湾',
  TJ: '塔吉克斯坦',
  TZ: '坦桑尼亚，联合共和国',
  TH: '泰国',
  TL: '东帝汶',
  TG: '多哥',
  TK: '托克劳',
  TO: '汤加',
  TT: '特立尼达和多巴哥',
  TN: '突尼斯',
  TR: '土耳其',
  TM: '土库曼斯坦',
  TC: '特克斯和凯科斯群岛',
  TV: '图瓦卢',
  UG: '乌干达',
  UA: '乌克兰',
  AE: '阿拉伯联合酋长国',
  GB: '英国',
  US: '美国',
  VI: '美属维尔京群岛',
  UY: '乌拉圭',
  UZ: '乌兹别克斯坦',
  VU: '瓦努阿图',
  VE: '委内瑞拉玻利瓦尔共和国',
  VN: '越南',
  WF: '瓦利斯和富图纳群岛',
  EH: '西撒哈拉',
  YE: '也门',
  ZM: '赞比亚',
  ZW: '津巴布韦'
};
export var MODEL_TAG = {
  recommend: '推荐',
  exclusive: '独家',
  new: '最新',
  first_release: '首发'
};
export var RECOMMEND_TYPE = {
  1: '作品',
  2: '模型',
  3: '账户',
  4: '作品集',
  5: '视频'
};
export var VIDEO_STATUS = ['全部', '私有', '公开', '下架'];