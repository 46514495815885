var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "tags",
    staticClass: "swipeab-leList",
    style: {
      marginTop: _vm.top
    }
  }, [_vm.iconBntShow ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowLeft,
      expression: "isShowLeft"
    }],
    staticClass: "swipeab-icon-box swipeab-icon-box-left",
    style: {
      width: _vm.iconBntWidth + "px"
    }
  }, [_c("div", {
    staticClass: "icon-bnt",
    style: {
      width: _vm.iconBntWidth + "px",
      height: _vm.iconBntWidth + "px",
      marginTop: "-" + _vm.iconBntWidth / 2 + "px"
    },
    on: {
      click: function click($event) {
        return _vm.tagsMove("-" + _vm.moveLeng);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-left"
  })])]) : _vm._e(), _vm._v(" "), _c("div", {
    ref: "scrollArea",
    staticClass: "scroll-area"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "list"
  }, [_vm._t("default")], 2)])]), _vm._v(" "), _vm.iconBntShow ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowRight,
      expression: "isShowRight"
    }],
    staticClass: "swipeab-icon-box swipeab-icon-box-right",
    style: {
      width: _vm.iconBntWidth + "px"
    }
  }, [_c("div", {
    staticClass: "icon-bnt",
    style: {
      width: _vm.iconBntWidth + "px",
      height: _vm.iconBntWidth + "px",
      marginTop: "-" + _vm.iconBntWidth / 2 + "px"
    },
    on: {
      click: function click($event) {
        return _vm.tagsMove(_vm.moveLeng);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-right"
  })])]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };