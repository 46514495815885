function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { videoList } from '@/api/home';
import videoBox from './components/videoBox.vue';
import { timeFormat } from '@/utils/tool';
import SwipeableList from './components/SwipeableList.vue';
import { videoCategory } from '@/api/home';
import { formatNumberForeign } from '@/utils/tool';
export default {
  name: 'Home',
  components: {
    videoBox: videoBox,
    SwipeableList: SwipeableList
  },
  data: function data() {
    return {
      timeFormat: timeFormat,
      formatNumberForeign: formatNumberForeign,
      isReq: false,
      isLock: false,
      imgScroll: null,
      tagList: [],
      list: [],
      queryParam: {
        category: '',
        page: 1,
        page_size: 40
      }
    };
  },
  computed: {
    isMobile: function isMobile() {
      return localStorage.getItem('visitFrom') === 'mobile';
    }
  },
  created: function created() {
    if (this.$route.query.category) {
      this.queryParam.category = this.$route.query.category;
    } else if (this.isMobile) {
      this.queryParam.category = 'All';
    }
    this.getList();
    if (this.isMobile) {
      this.getType();
    }
  },
  mounted: function mounted() {
    var _this = this;
    window.addEventListener('scroll', this.checkLoadMore);
    this.$once('hook:beforeDestroy', function () {
      window.removeEventListener('scroll', _this.checkLoadMore);
    });
  },
  methods: {
    getType: function getType() {
      var _this2 = this;
      videoCategory().then(function (res) {
        if (res.status.code != 10000) return;
        _this2.tagList = res.data.items;
        _this2.tagList.unshift('All');
        _this2.$nextTick(function () {
          _this2.$refs.tagItem.forEach(function (ele, idx) {
            if (ele.classList.contains('tagActivate')) {
              _this2.imgScroll = {
                value: ele.getBoundingClientRect().left,
                idx: idx
              };
            }
          });
        });
      });
    },
    checkLoadMore: function checkLoadMore() {
      var documentHeight = document.documentElement.scrollHeight;
      var clientHeight = window.innerHeight;
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      var distanceToBottom = documentHeight - this.scrollTop - clientHeight;
      if (distanceToBottom < 1.5 * clientHeight) {
        this.getList(true);
      }
    },
    goPage: function goPage(item) {
      if (this.isMobile) {
        this.$router.push("/video/" + item.id);
      } else {
        window.open("/video/" + item.id, "_blank");
      }
    },
    formatDate: function formatDate(timestamp) {
      var date = new Date(timestamp);
      var month = date.getMonth() + 1;
      var day = date.getDate();
      return "".concat(month, "-").concat(day);
    },
    changeTag: function changeTag(item) {
      var _this3 = this;
      if (this.isReq) return;
      this.queryParam.category = item;
      this.queryParam.page = 1;
      this.list = [];
      this.isLock = false;
      this.getList();
      this.$nextTick(function () {
        _this3.$refs.tagItem.forEach(function (ele, idx) {
          if (ele.classList.contains('tagActivate')) {
            _this3.imgScroll = {
              value: ele.getBoundingClientRect().left,
              idx: idx
            };
          }
        });
      });
    },
    getList: function getList(add) {
      var _this4 = this;
      //获取瀑布流列表
      if (this.isReq || this.isLock) return;
      this.isReq = true;
      var params = _objectSpread({}, this.queryParam);
      if (params.category == 'All') {
        params.category = '';
      }
      videoList(params).then(function (res) {
        if (res.status.code === 10000) {
          if (!res.data.items || res.data.items.length == 0) {
            _this4.isLock = true;
          } else {
            _this4.isLock = false;
          }
          if (add) {
            //翻页时才添加数据，切换tab时重置列表，解决快速切换tab时页面显示和错误问题
            _this4.list = _this4.list.concat(res.data.items || []);
          } else {
            _this4.list = res.data.items || [];
          }
          _this4.isReq = false;
          _this4.queryParam.page++;
        }
      }).catch(function (e) {
        _this4.isReq = false;
      });
    }
  }
};