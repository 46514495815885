// import { login } from '@/api/user';
import { getToken, setToken } from '@/utils/auth';
var state = {
  token: getToken()
};
var getters = {
  isLoggedIn: function isLoggedIn(state) {
    return !!state.token;
  } // 判断用户是否已登录
};

var mutations = {
  updateLoginStatus: function updateLoginStatus(state, isUsed) {
    state.used = isUsed;
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
    setToken(token);
  },
  CLEAR_USER_INFO: function CLEAR_USER_INFO(state) {
    state.token = '';
  }
};
var actions = {
  updateToken: function updateToken(_ref, token) {
    var commit = _ref.commit;
    commit('SET_TOKEN', token);
  },
  setToken: function setToken(_ref2, token) {
    var commit = _ref2.commit;
    commit('SET_TOKEN', token);
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};