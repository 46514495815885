export default {
  more: 'もっと見る',
  All: 'すべて',
  CopiedURLToClipboard: '画像リンクをコピーしました',
  dleNovel: '放置小説',
  recommendedVideo: 'おすすめ動画',
  urban: '都市',
  campus: 'キャンパス',
  fantasy: 'ファンタジー',
  magic: '魔法',
  martialArts: '武侠',
  romance: 'ロマンス',
  fanFiction: '二次創作',
  comedy: 'コメディー',
  history: '歴史',
  game: 'ゲーム',
  military: '軍事',
  supernatural: '超自然現象',
  mystery: 'ミステリー',
  allSet: '全集{number}話',
  episode: '第{number}話',
  Chinese: '中文',
  English: 'English',
  Japanese: '日本語',
  Portuguese: 'Português'
};