import { isSupportWebp } from '@/utils/tool';
var state = {
  isFixed: true,
  isSupportWebp: isSupportWebp(),
  tagHot: [],
  showMask: false,
  homeType: 'work',
  // Checkpoint LORA work
  imagePageFromPath: '',
  clientComputing: 1 // 1=>local 2=>platform 
};

var mutations = {
  changeIsfixed: function changeIsfixed(state, data) {
    state.isFixed = data;
  },
  setTagHot: function setTagHot(state, data) {
    data.forEach(function (element) {
      element.style = JSON.parse(element.style);
    });
    state.tagHot = data;
  },
  setMask: function setMask(state, data) {
    state.showMask = !!data;
  },
  setHomeType: function setHomeType(state, data) {
    state.homeType = data;
  },
  setImagePageFromPath: function setImagePageFromPath(state, data) {
    state.imagePageFromPath = data;
  },
  setClientComputing: function setClientComputing(state, data) {
    state.clientComputing = data;
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};