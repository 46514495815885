import localforage from 'localforage';
var logLocalErr = function logLocalErr() {
  var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  console.log(key + (value || 'Local storage') + ' not available');
};
export function getUsed() {
  try {
    if (localStorage.getItem('used') || localStorage.getItem('userInfo')) {
      return localStorage.getItem('used') || '1';
    } else {
      return '';
    }
  } catch (error) {
    logLocalErr();
    return '';
  }
}
export function getToken() {
  try {
    if (localStorage.getItem('t')) {
      setUsed('1', true);
      return localStorage.getItem('t');
    } else {
      return '';
    }
  } catch (error) {
    logLocalErr();
    return '';
  }
}

// auto用于给老用户默认记录
export function setUsed() {
  var used = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '1';
  var auto = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  try {
    // console.log('setUsed', used, auto);
    var localUsed = localStorage.getItem('used');
    if (auto) {
      if (!localUsed) {
        localStorage.setItem('used', used);
      }
      return;
    }
    if (localUsed !== used) {
      localStorage.setItem('used', used);
    }
  } catch (e) {
    logLocalErr();
  }
}
export function setToken(token) {
  try {
    localStorage.setItem('t', token);
  } catch (e) {
    logLocalErr();
  }
}
export function removeToken() {
  try {
    localStorage.removeItem('t');
  } catch (e) {
    logLocalErr();
  }
}
export function getUserInfo() {
  try {
    if (localStorage.getItem('userInfo')) {
      return JSON.parse(localStorage.getItem('userInfo'));
    }
  } catch (e) {
    logLocalErr();
  }
  return {};
}
export function setUserInfo(userInfo) {
  try {
    var info = JSON.stringify(userInfo);
    var device_code = userInfo.device_code;
    localStorage.setItem('userInfo', info);
    setUsed('1', true);
    if (device_code) {
      localStorage.setItem('deviceId', device_code);
    }
  } catch (e) {
    logLocalErr();
  }
}
export function removeUserInfo() {
  try {
    localStorage.removeItem('userInfo');
  } catch (e) {
    logLocalErr();
  }
}
// 获取本地存储里的账户信息
export function getAccountInfo() {
  try {
    if (localStorage.getItem('wsAccountInfo')) {
      return JSON.parse(localStorage.getItem('wsAccountInfo'));
    }
  } catch (e) {
    logLocalErr();
  }
  return {};
}
// 获取本地存储里的任务配置信息
export function getTaskOptionsInfo() {
  try {
    if (localStorage.getItem('wsTaskOptionsInfo')) {
      return JSON.parse(localStorage.getItem('wsTaskOptionsInfo'));
    }
  } catch (e) {
    logLocalErr();
  }
  return {};
}

// 是否有后台权限
export default function isAdmin() {
  var _getUserInfo = getUserInfo(),
    is_admin = _getUserInfo.is_admin;
  return is_admin;
}

// 移除支付信息
export function removePaymentInfo() {
  try {
    localStorage.removeItem('paymentInfo');
  } catch (e) {
    logLocalErr();
  }
}

// 设置已读通知
export function setReadNoticeLocal(value) {
  try {
    var _value$userId = value.userId,
      userId = _value$userId === void 0 ? '' : _value$userId,
      _value$readList = value.readList,
      readList = _value$readList === void 0 ? [] : _value$readList;
    var v = JSON.stringify(readList);
    localStorage.setItem("NOTICEREADLIST_".concat(userId), v);
  } catch (e) {
    logLocalErr();
  }
}
// 获取已读通知
export function getReadNoticeLocal() {
  try {
    var _getUserInfo2 = getUserInfo(),
      _getUserInfo2$id = _getUserInfo2.id,
      id = _getUserInfo2$id === void 0 ? '' : _getUserInfo2$id;
    var key = "NOTICEREADLIST_".concat(id);
    // console.log('获取本地', key);
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
  } catch (e) {
    logLocalErr('getReadNoticeLocal ');
    return [];
  }
  return [];
}

// 存 u_code
export function setUCode(u_code) {
  try {
    localStorage.setItem('u_code', u_code);
  } catch (e) {
    logLocalErr('UCode');
  }
}

// 取: u_code
export function getUCode() {
  try {
    return localStorage.getItem('u_code');
  } catch (e) {
    logLocalErr('UCode');
    return '';
  }
}

// 移除: u_code
export function removeUCode() {
  try {
    localStorage.removeItem('u_code');
  } catch (e) {
    logLocalErr('UCode');
  }
}

// 存: b站配置
export function setBiliConfig(config) {
  try {
    var biliconfig = JSON.stringify(config);
    localStorage.setItem('biliConfig', biliconfig);
  } catch (e) {
    logLocalErr('biliConfig ');
  }
}

// 取: b站配置
export function getBiliConfig() {
  try {
    var config = localStorage.getItem('biliConfig');
    return config ? JSON.parse(config) : {};
  } catch (e) {
    logLocalErr('biliConfig ');
    return {};
  }
}

// 移除: b站配置
export function removeBiliConfig() {
  try {
    localStorage.removeItem('biliConfig');
  } catch (e) {
    logLocalErr('biliConfig');
  }
}

// 移除除了部分key外所有localStorage
export function clearLocalStorageExcept() {
  var keys = ['language', 'visitFrom', 'deviceId', 'isAdvLog', 'used', 'channelInviteCode', 'GenetateMenuZoom', 'clientDeviceId', 'Source', 'Port'];
  var allKeys = Object.keys(localStorage);
  allKeys.forEach(function (key) {
    if (!keys.includes(key) && !key.startsWith('NOTICEREADLIST_') && !key.startsWith('dialog_')) {
      try {
        localStorage.removeItem(key);
      } catch (e) {
        logLocalErr();
      }
    }
  });
}