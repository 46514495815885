import headBox from './components/headBox.vue';
import determineSource from '@/utils/determineSource';
export default {
  name: 'App',
  components: {
    headBox: headBox
  },
  data: function data() {
    return {
      cachedViews: []
    };
  },
  created: function created() {
    localStorage.setItem('visitFrom', determineSource());
  },
  computed: {},
  watch: {
    $route: function $route(to, from) {
      // 判断是否进行路由缓存
      this.setCachedViews(['Home'], to, from);
    }
  },
  methods: {
    /**
     * 设置需要进行判断缓存的页面
     * list:缓存页面的name列表，注意页面name需要跟router路由设置的name一致
     * to:监听$route中的to需要传进来
     */
    setCachedViews: function setCachedViews(list, to, from) {
      var _this = this;
      list.forEach(function (ele) {
        if (to.name === ele) {
          if (_this.cachedViews.includes(ele)) return;
          _this.cachedViews.push(ele);
        } else if (to.name !== 'VideoDetail' && _this.cachedViews.includes(ele)) {
          _this.cachedViews.splice(_this.cachedViews.indexOf(ele), 1);
        }
      });
    }
  }
};