function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getUserInfo } from './utils/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
var firebaseConfig = {
  apiKey: "AIzaSyBVQAHnqU5tXfdf-eKGteocMC5_FjPfK5c",
  authDomain: "idlenovel.firebaseapp.com",
  projectId: "idlenovel",
  storageBucket: "idlenovel.appspot.com",
  messagingSenderId: "503560905429",
  appId: "1:503560905429:web:c6023f57a9c1794044785c",
  measurementId: "G-77Q5SY9797"
};

// Initialize Firebase
var app = initializeApp(firebaseConfig);

// 打点配置
var analytics = getAnalytics(app);

//性能监听
var perf = getPerformance(app);
function notificationAuth() {
  var messageAuth = Notification.permission;
  console.log('用户当前消息权限：', messageAuth);
  if (messageAuth === 'default') {
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        console.log('用户已开通消息权限');
      }
    });
  }
}

// firebase/messaging 不支持移动端 会报错阻塞移动端渲染
try {
  // //消息配置
  var messaging = getMessaging(app) || '';
  onMessage(messaging, function (payload) {
    var _payload$data = payload.data,
      type = _payload$data.type,
      data = _payload$data.data,
      account_id = _payload$data.account_id;
    var _ref = getUserInfo() || {},
      id = _ref.id;
    var _data = JSON.parse(data);
    console.log('前台收到消息：', payload, _data, id, account_id);
    if (account_id === id) _Message.success('任务' + (_data.status === 3 ? '完成啦' : '已取消'));
  });
  notificationAuth(); // 消息权限访问
} catch (error) {
  messaging = '';
  console.log('err', error);
}

// 初始化浏览器设备Id
function initDeviceKey() {
  var deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = createdDeviceId();
    localStorage.setItem('deviceId', deviceId);
  }
  // 单独给客户端生成一个设备id
  var clientDeviceId = localStorage.getItem('clientDeviceId');
  if (!clientDeviceId) {
    clientDeviceId = createdDeviceId();
    localStorage.setItem('clientDeviceId', clientDeviceId);
  }
  console.log('当前设备ID:' + deviceId);
  console.log('客户端当前设备ID:' + clientDeviceId);
}
//  生成设备ID
function createdDeviceId() {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }

  var KEY = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0; // d是随机种子
    d = Math.floor(d / 16);
    return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
  return KEY;
}

//广告打点（进入网站）add_event事件打点
function advLog() {
  var isLog = localStorage.getItem('isAdvLog');
  var keys = ['tagId', 'campaign', 'ad', 'media_source'];
  if (isLog) {
    return false;
  }
  var urlParams = new URLSearchParams(window.location.search);
  var params = {
    tagId: '',
    campaign: '',
    ad: '',
    media_source: ''
  };
  var _iterator = _createForOfIteratorHelper(urlParams),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
        key = _step$value[0],
        value = _step$value[1];
      params[key] = String(value);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  addLogEvent('ad_event', _objectSpread({}, params));
  localStorage.setItem('isAdvLog', true);
}

// 设备每次打开网站都得打点
function addDeviceLog() {
  var _ref2 = getUserInfo() || {},
    id = _ref2.id;
  var params = {
    user_status: 'guest'
  };
  if (id) {
    params.user_status = 'activated';
  }
  addLogEvent('log_open_devices', params);
}
function getDeviceOS() {
  var userAgent = navigator.userAgent;
  if (userAgent.includes('Android')) {
    return "android";
  } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    return "ios";
  } else {
    return "web";
  }
}
function addLogEvent(eventName, params) {
  var _ref3 = getUserInfo() || {},
    account_id = _ref3.account_id,
    id = _ref3.id,
    account_name = _ref3.account_name,
    name = _ref3.name,
    email = _ref3.email,
    create_at = _ref3.create_at;
  var device_id = localStorage.getItem('deviceId');
  var device_os = getDeviceOS();
  if (eventName === 'log_actions') {
    params.user_status = id ? 'activated' : 'guest';
  }
  return logEvent(analytics, eventName, _objectSpread({
    account_id: account_id || id,
    account_name: account_name || name || id,
    mail: email,
    reg_at: create_at,
    create_at: Date.parse(new Date()),
    page_location: location.href,
    device_id: device_id,
    device_os: device_os,
    plat: 'web'
  }, params));
}
initDeviceKey(); // 生成设备ID
advLog(); // 第一次进入网站打点
addDeviceLog(); // 新增设备打开网站打点

export default {
  install: function install(Vue, option) {
    Vue.prototype.$logEvent = addLogEvent;
  }
};