function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
export default [{
  path: '/',
  name: '/',
  redirect: '/home',
  meta: {}
}, {
  path: '/home',
  name: 'Home',
  meta: {
    iconClass: 'home-icon',
    noPadding: false
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/home/index.vue'));
    });
  }
}, {
  path: '/video/:id',
  name: 'VideoDetail',
  meta: {
    iconClass: 'home-icon',
    noPadding: false
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/videoInfo/index.vue'));
    });
  }
}, {
  path: '/403',
  name: '403',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/403/index.vue'));
    });
  },
  meta: {}
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/404/index.vue'));
    });
  },
  meta: {}
}, {
  path: '*',
  name: '*',
  redirect: '/home',
  // redirect: '/login', // 封测期间，暂时跳转到登录
  meta: {}
}];