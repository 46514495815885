function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
// 进行多语言支持配置
import Vue from 'vue'; // 引入Vue
import VueI18n from 'vue-i18n'; // 引入国际化的插件包
import locale from 'element-ui/lib/locale';
import elementEN from 'element-ui/lib/locale/lang/en'; // 引入饿了么的英文包
import elementZH from 'element-ui/lib/locale/lang/zh-CN'; // 引入饿了么的中文包
import elementJA from 'element-ui/lib/locale/lang/ja'; // 引入饿了么的日文包
import elementPT from 'element-ui/lib/locale/lang/pt'; // 引入饿了么的葡萄牙语包
import zhCn from './zhCn'; // 引入中文
import en from './en'; // 引入英文
import ja from './ja'; // 引入日文
import pt from './pt'; // 引入葡萄牙语

Vue.use(VueI18n); // 全局注册国际化包

//获取本地语种
var lang = localStorage.getItem('language') || navigator.language || navigator.browserLanguage;
if (lang == 'zh') {
  lang = 'zh-CN';
}
// 创建国际化插件的实例
var i18n = new VueI18n({
  // 指定语言类型 zh表示中文  en表示英文
  locale: lang,
  // 将elementUI语言包加入到插件语言数据里
  messages: {
    // 英文环境下的语言数据
    en: _objectSpread(_objectSpread({}, elementEN), en),
    // 中文环境下的语言数据
    zh: _objectSpread(_objectSpread({}, elementZH), zhCn),
    // 日文环境下的语言数据
    ja: _objectSpread(_objectSpread({}, elementJA), ja),
    // 葡萄牙语境下的语言数据
    pt: _objectSpread(_objectSpread({}, elementPT), pt)
  },
  // 不显示翻译错误的warning信息
  silentTranslationWarn: true
});
// 配置elementUI 语言转换关系
locale.i18n(function (key, value) {
  return i18n.t(key, value);
});
export default i18n;

// 重新封装方法
export function $t(args) {
  return i18n.tc.call(i18n, args);
}